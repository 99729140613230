import * as React from "react";
import {
  Button,
  Link,
  ButtonGroup,
  Box,
  Stack,
  IconButton,
  Badge,
  Typography,
  MenuItem,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";
import logo from "./resource/tp_logo.png";
import { ShoppingCart } from "./checkout";
import MenuPopup from "./common/MenuPopup";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import { get_cart_size } from "./api";
import { useQuery, useQueryClient } from "@tanstack/react-query";

function Header() {
  const theme = useTheme();

  const small = useMediaQuery(theme.breakpoints.down("sm"));
  const medium = useMediaQuery(theme.breakpoints.down("md"));
  const large = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <Box sx={{ minHeight: "9vh" }}>
      {small ? <SmImageLogo /> : <ImageLogo />}
      {!large && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-around"
        >
          <LeftButtons />
          <RightButtons />
        </Stack>
      )}
      {large && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          padding={2}
          sx={{ height: "6vh" }}
        >
          <MenuPopup icon={<MenuIcon sx={{ color: "#fff" }} />}>
            <Box>
              <MenuItem component={Link} href="/about">
                About Us
              </MenuItem>
              <MenuItem component={Link} href="/faq">
                FAQ
              </MenuItem>
              <MenuItem component={Link} href="/contact">
                Contact Us
              </MenuItem>
              <MenuItem component={Link} href="/Shop/ENERGY%20DRINKS">
                Shop Now
              </MenuItem>
            </Box>
          </MenuPopup>
          <ShoppingCartGroup />
        </Stack>
      )}
    </Box>
  );
}
function ShoppingCartGroup() {
  const { data, status } = useQuery(["cart_size"], get_cart_size, {
    onSuccess: (d) => setState(d),
  });
  const [state, setState] = React.useState(0);
  return (
    <ButtonGroup>
      <IconButton href="/checkout">
        <Badge badgeContent={state} color="error">
          <ShoppingCartIcon sx={{ color: "#fff" }} />
        </Badge>
      </IconButton>
    </ButtonGroup>
  );
}
function SmImageLogo() {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        position: "absolute",
        width: "100%",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          height: "6vh",
          width: "100%",
          background: "#094185",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: "6vh",
          height: "3vh",
          width: "100%",
          background: "#d9d9d9",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      ></Box>
      <Box
        sx={{
          zIndex: 1000,
          height: "8vh",
        }}
        onClick={() => {
          navigate("/");
        }}
        component="img"
        src={logo}
      />
    </Box>
  );
}
function ImageLogo() {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        position: "absolute",
        width: "100%",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          height: "6vh",
          width: "100%",
          background: "#094185",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: "6vh",
          height: "3vh",
          width: "100%",
          background: "#d9d9d9",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Box sx={{ width: "100%" }} />
        <Typography
          align="center"
          variant="button"
          sx={{ width: "100%", pl: 20 }}
        >
          Spend $100 = FREE SHIPPING
        </Typography>
      </Box>
      <Box
        sx={{
          zIndex: 1000,
          height: "10vh",
        }}
        onClick={() => {
          navigate("/");
        }}
        component="img"
        src={logo}
      />
    </Box>
  );
}
function RightButtons() {
  const navigate = useNavigate();
  return (
    <Stack
      direction="row"
      spacing={4}
      justifyContent="flex-end"
      alignItems="flex-start"
      sx={{
        mb: 2,
        width: "100%",
        display: "inline-flex",
        color: "white",
        zIndex: 100,
      }}
    >
      <Button
        sx={{ fontSize: 20, width: "60%" }}
        color="inherit"
        onClick={() => {
          navigate("/");
          window.scrollTo({
            left: 0,
            top: document.body.scrollHeight,
            behavior: "smooth",
          });
        }}
      >
        <b>Shop Now</b>
      </Button>
      <ButtonGroup>
        <IconButton
          color="inherit"
          href="https://www.instagram.com/bigblue_energy/"
        >
          <InstagramIcon />
        </IconButton>
        <IconButton
          color="inherit"
          href="https://www.facebook.com/BigBlueEnergyDrinks/"
        >
          <FacebookIcon />
        </IconButton>
        <Divider />
        <ShoppingCartGroup />
      </ButtonGroup>
    </Stack>
  );
}
function LeftButtons() {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
      spacing={4}
      sx={{
        mb: 2,
        width: "100%",
        display: "inline-flex",
        color: "white",
        zIndex: 100,
      }}
    >
      <Button sx={{ fontSize: 20 }} color="inherit" href="/about">
        <b>About Us</b>
      </Button>
      <Button sx={{ fontSize: 20 }} color="inherit" href="/faq">
        Faq
      </Button>
      <Button sx={{ fontSize: 20 }} color="inherit" href="/contact">
        Contact Us
      </Button>
    </Stack>
  );
}

export default Header;
